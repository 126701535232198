<template>
  <div id="filters">
    <b-navbar toggleable="lg" v-show="!loading">

      <!--
      <b-navbar-nav class="mt-4 mr-4 with-label-prepended" data-label="PROSPECTING">
        <b-list-group horizontal="md" v-show="!loading">
          <b-list-group-item
            action
            button
            :disabled="loadingCompanies"
            :active="prospecting"
            @click="toggleProspecting()"
          >Company Search</b-list-group-item>
        </b-list-group>
      </b-navbar-nav>
      -->

      <b-navbar-nav class="mt-4 mr-4 with-label-prepended filters-sites-list" data-label="WEBSITE ACTIVITY">
        <span v-show="loading">Loading sites...</span>
        <b-list-group horizontal="md" v-show="!loading" >
          <b-list-group-item
            v-for="site in userSites"
            :key="site.id"
            action
            button
            :disabled="loadingCompanies || prospecting"
            :active="selectedSiteIDs.includes(site.id) && !prospecting"
            @click="toggleSelected(site.id)"
          >{{ site.name }}</b-list-group-item>
        </b-list-group>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto mt-4 with-label-prepended" data-label="DATE RANGE" v-show="!prospecting">
        <b-form>
          <div style="float: left;">
            <b-form-datepicker
              v-model="dateFrom"
              :max="dateTo"
              right
              @input="onChangedDateFrom"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder="Start Date"
              :disabled="loading"
              value-as-date
            ></b-form-datepicker>
          </div>
          <span class="mx-2">-</span>
          <div style="float: right;">
            <b-form-datepicker
              v-model="dateTo"
              :min="dateFrom"
              right
              @input="onChangedDateTo"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder="End Date"
              :disabled="loading"
              value-as-date
            ></b-form-datepicker>
          </div>
        </b-form>
      </b-navbar-nav>

    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Filters',
  computed: {
    ...mapGetters('settings/sites', {
      loading: 'loading',
    }),
    ...mapGetters('filters', {
      selectedSiteIDs: 'selectedSiteIDs',
      prospecting: 'prospecting',
      userSites: 'userSites',
    }),
    ...mapGetters('companies', {
      loadingCompanies: 'loading',
    }),
  },
  data() {
    const sDate = new Date()
    sDate.setDate(sDate.getDate() - 6)
    return {
      dateFrom: sDate,
      dateTo: new Date(),
    }
  },
  filters: {
    datetime: function(value) {
      if (!value) return ''
      return new Date(value).toLocaleString()
    },
  },
  methods: {
    toggleProspecting() {
      this.$store.commit('filters/setProspecting', !this.prospecting)
      this.$emit('filterChanged')
    },
    toggleSelected(site_id) {
      this.$store.commit('filters/toggleSelected', site_id)
      this.$emit('filterChanged')
    },
    onFilterChanged() {
      this.$store.commit('companies/resetPage')
      this.$emit('filterChanged')
    },
    onChangedDateFrom(dateFrom) {
      this.$store.commit('filters/setDateFrom', dateFrom)
      this.onFilterChanged()
    },
    onChangedDateTo(dateTo) {
      this.$store.commit('filters/setDateTo', dateTo)
      this.onFilterChanged()
    },
  },
  mounted: function() {
    this.$store.commit('filters/setDateFrom', this.dateFrom)
    this.$store.commit('filters/setDateTo', this.dateTo)
  }
}
</script>