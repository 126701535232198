<template>
  <div class="page-dashboard">
    <Companies />
  </div>
</template>

<script>
import Companies from '@/components/Companies.vue'

export default {
  name: 'Dashboard',
  components: {
    Companies,
  },
}
</script>